import React from 'react';
import { mq } from '../../theme';
import Container from '../Container';
import { Heading2, Paragraph } from '../Heading/Text';
import BackgroundImage from '../BackgroundImage/BackgroundImage';
import WebsiteButton from '../WebsiteButton';

const Newsletter = () => {
  return (
    <BackgroundImage
      src="newsletter.jpg"
      css={mq({
        backgroundColor: '#cbcbcb',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto 100%',
        opacity: 0.3,
        backgroundPosition: 'bottom right'
      })}
    >
      <Container css={{ alignItems: 'center' }}>
        <div css={mq({ width: [450, 'auto'] })}>
          <Heading2 light>Le bâtiment et le numérique</Heading2>
          <Paragraph>
            Une newsletter dédiée aux professionnels du bâtiment pour allier
            technologie et&nbsp;expertise&nbsp;métier&nbsp;!
          </Paragraph>
          <WebsiteButton
            variant="outline"
            href="http://info.web2vi.com/newsletter-numerique"
          >
            découvrez la newsletter
          </WebsiteButton>
        </div>
      </Container>
    </BackgroundImage>
  );
};

export default Newsletter;
